//提供首页相关APi
import request from '@/utils/request'

/**
 * 获取品牌个数
*/
export const findBrand = (limit=6) => {
  return request('/home/brand','get',{limit})
}

/**
 * Banner轮播
*/
export const findBanner = () => {
  return request('/home/banner','get')
}


/**
 * 获取新鲜好物
*/
export const findNew = () => {
  return request('/goods/all','post', {})
}

/**
 * 获取人气推荐
*/
export const findHot = () => {
  return request('/goods/all','post', {})
}

/**
 * 获取最新专题
*/
export const findSpecial = () => {
  console.log('qweqeq');
  return request('/home/special','get')
}

export const findAllGoods = () => {
  return request('/goods/all','post', {})
}

export const findGoodsById = (id) => {
  return request('/goods/find','post', {id})
}


export const alipayOrder = (data) => {
  console.log('pay alipay data ', data)
  return request('/pay/alipay','post', data)
}

export const wechatPayOrder = (data) => {
  console.log('pay alipay data ', data)
  return request('/pay/wechatPay','post', data)
}

export const gateWayPayOrder = (data) => {
  console.log('pay gateWayPay data ', data)
  return request('/pay/gateWayPay','post', data)
}

export const findOrderListByRequired = (data) => {
  return request('/order/query','post', data)
}

export const findOrderByTrace = (data) => {
  return request('/order/tracequery','post', data)
}

export const refundOrder = (data) => {
  return request('/pay/refund','post', data)
}

export const checkCustomer = (data) => {
  return request('/order/checkUserName', 'post', data)
}
